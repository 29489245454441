import React from 'react'
import img16 from "../images/img16.jpg"

const SubscribeSection = () => {
    return (
        <section className="subscribe-section" style={{ backgroundImage: `url(${img16})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-heading section-heading-white">
                            <h5 className="dash-style">HOLIDAY PACKAGE OFFER</h5>
                            <h2>HOLIDAY SPECIAL 25% OFF !</h2>
                            <h4>Sign up now to recieve hot special offers and information about the best tour packages, updates and discounts !!</h4>
                            <div className="newsletter-form">
                                <form>
                                    <input type="email" name="s" placeholder="Your Email Address" />
                                    <input type="submit" name="signup" value="SIGN UP NOW!" />
                                </form>
                            </div>
                            <p>Enjoy a festive 25% discount with our Holiday Special! Sign up today for exclusive offers on top tour packages and stay updated with the latest discounts and updates.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SubscribeSection