import React from "react";
import { Link } from "react-router-dom";
import img11 from "../../images/img11.jpg";

const Sidebar = () => {
  const divStyle = {
    backgroundImage: `url(${img11})`,
  };
  return (
    <div className="sidebar">
      <div className="package-price">
        <h5 className="price">
          <span>$649</span> / per person
        </h5>
        <div className="start-wrap">
          <div className="rating-start" title="Rated 5 out of 5">
            <span style={{ width: "60%" }}></span>
          </div>
        </div>
      </div>
      {/* Booking form */}
      <div className="widget-bg booking-form-wrap">
        <h4 className="bg-title">Booking</h4>
        <form className="booking-form">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  name="name_booking"
                  type="text"
                  placeholder="Full Name"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input name="email_booking" type="text" placeholder="Email" />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input name="phone_booking" type="text" placeholder="Number" />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <input
                  className="input-date-picker"
                  type="text"
                  name="s"
                  autoComplete="off"
                  readOnly="readonly"
                  placeholder="Date"
                />
              </div>
            </div>
            <div className="col-sm-12">
              <h4 className="">Add Options</h4>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="checkbox-list">
                  <input type="checkbox" name="s" />
                  <span className="custom-checkbox"></span>
                  Tour guide
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="checkbox-list">
                  <input type="checkbox" name="s" />
                  <span className="custom-checkbox"></span>
                  Insurance
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="checkbox-list">
                  <input type="checkbox" name="s" />
                  <span className="custom-checkbox"></span>
                  Dinner
                </label>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="checkbox-list">
                  <input type="checkbox" name="s" />
                  <span className="custom-checkbox"></span>
                  Bike rent
                </label>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group submit-btn">
                <input type="submit" name="submit" value="Book Now" />
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* Travel tips */}
      <div className="widget-bg information-content text-center">
        <h5>TRAVEL TIPS</h5>
        <h3>NEED TRAVEL RELATED TIPS & INFORMATION</h3>
        <p>
          Mollit voluptatem perspiciatis convallis elementum corporis quo
          veritatis aliquid blandit, blandit torquent, odit placeat.{" "}
        </p>
        <Link to="#" className="button-primary">
          GET A QUOTE
        </Link>
      </div>
      {/* More packages */}
      <div className="travel-package-content text-center" style={divStyle}>
        <h5>MORE PACKAGES</h5>
        <h3>OTHER TRAVEL PACKAGES</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          tellus, luctus.
        </p>
        <ul>
          <li>
            <a href="#">
              <i className="far fa-arrow-alt-circle-right"></i>Vacation packages
            </a>
          </li>
          <li>
            <a href="#">
              <i className="far fa-arrow-alt-circle-right"></i>Honeymoon
              packages
            </a>
          </li>
          <li>
            <a href="#">
              <i className="far fa-arrow-alt-circle-right"></i>New year packages
            </a>
          </li>
          <li>
            <a href="#">
              <i className="far fa-arrow-alt-circle-right"></i>Weekend packages
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
