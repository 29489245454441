import React from "react";

import AdventureAndActivity from "../components/adventureAndActivity";
import SectionHeading from "../components/sectionheading";
import HomeSliderSection from "../components/slider/heroslider";
import SpecialTravelOffer from "../components/specialTravelOffer";
import TopNotchDeals from "../components/topNotchDeals";

import img7 from "../images/img7.jpg";
import img8 from "../images/img8.jpg";
import img9 from "../images/img9.jpg";
import img12 from "../images/img12.jpg";
import img13 from "../images/img13.jpg";
import img14 from "../images/img14.jpg";
import img15 from "../images/img15.jpg";
import camelTrip from "../images/camel-trip.jpg";



import img24 from "../images/img24.jpg";

import icon12 from "../images/icon12.png";
import icon13 from "../images/icon13.png";
import icon14 from "../images/icon14.png";
import SubscribeSection from "../components/subscribeSection";
import WhatsAppButton from "../components/shared/WhatsAppButton";
import ScrollToTopButton from "../components/shared/ScrollToTopButton";
import TestimonialSection from "../components/TestimonialSection";
import ClientSection from "../components/ClientSection";
import CallbackSection from "../components/CallbackInnerSection";
import PackageCard from "../components/packages/packagecard";
import CustomButton from "../components/shared/customButton";
import { ADDRESS, INFO_MAILE, PHONE1, PHONE2 } from "../globals";
import { Link } from 'react-router-dom';

const Home = () => {
  const packages = [
    {
      id: 1,
      image: img9,
      price: "$633,00",
      oldPrice: "$700,00",
      duration: "7D/6N",
      people: "5",
      location: "Egypt,Cairo",
      title: `Cairo Day tour 
    Starting point : Cairo or Giza Hotel 
    Ending point : Cairo or Giza Hotel 
    `,
      reviews: "25 reviews",
      rating: "60%",
      description: `Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:
    
    1- Visit Egyptrian museum 
    2- Visit El Moez Street
    3- Visit khan khalili
    
    Meal : lunch 
    (Duration 4-5Hours)
    `,
    },
    {
      id: 2,
      image: img7,
      price: "$633,00",
      oldPrice: "$720,00",
      duration: "5D/4N",
      people: "8",
      location: "Egypt",
      title: `Day tour from Cairo  to Luxor  
    (BY Flight )
    Starting point : Cairo  Hotel 
    Ending point : Cairo Hotel
    `,
      reviews: "17 reviews",
      rating: "100%",
      description: `Pickup from Cairo Hotel to the Airport take Domestic Flight to Luxor 
    
    Visit Karnak Temples  
    Visit Luxor Temples ( from out side)
    Visit valley of the kings 
    Visit Colossi of Memnon
    
    Meal : Lunch 
    
    Then transfer back to Luxor Airport Fly back to Cairo.
    
    (Duration  about 10 Hours)
    `,
    },
    {
      id: 3,
      image: camelTrip,
      price: "$100,00",
      oldPrice: "$140,00",
      duration: "6D/5N",
      people: "6",
      location: "Egypt",
      title: `Day tour from Hurghada to Cairo 
    (BY Flight )
    Starting point : Hurghada Hotel 
    Ending point : Hurghada Hotel 
    
    `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Pickup from Hurghada Hotel to the Airport take Domestic Flight to Cairo 
    
    Visit the Egyptian Museum 
    Visit the Pyramids of Giza
    Meals: Lunch 
    
    (Duration 9 Hours)
    
    After finishing the Tour.
     Then transfer back to Cairo  Airport Fly back to Hurghada.
    `,
    },
    {
      id: 4,
      image: img8,
      price: "$633,00",
      oldPrice: "$750,00",
      duration: "6D/5N",
      people: "6",
      location: `Egypt`,
      title: `Cairo Day tour 
    Starting point : Cairo or Giza Hotel 
    Ending point : Cairo or Giza Hotel 
    `,
      reviews: "22 reviews",
      rating: "100%",
      description: `Day tour to the Pyramids 
    Pickup From hotel 
    Visit Giza Pyramids and Sphinx.
    Visit Egyptian Museum
    Visit old Cairo 
    
    Meal : Lunch
    
    Your Tour starts by pick-up from the Hotel by our Licensed and Experienced Tour Guide and our Modern Brand-New Private A/C Coach and then proceed to:
    
     (Duration 8 Hours)
    `,
    },

    // Add other package details here...
  ];
  return (
    <>
      <main id="content" class="site-main">
        <HomeSliderSection />
        <section class="package-section recommended">
          <div class="container-fluid">
            <SectionHeading
              title="EXPLORE GREAT PLACES"
              headingTextAlignment="text-center"
              containerTextsStyle="col-lg-8 offset-lg-2 mt-5"
            >
              <h4>Your recently viewed and recommended trips</h4>
            </SectionHeading>
            <div class="package-inner">
              <div class="row px-lg-5">
                {packages.map((item) => (
                  <PackageCard
                    recommended={true}
                    people={item.people}
                    location={item.location}
                    rating={item.rating}
                    reviews={item.reviews}
                    duration={item.duration}
                    image={item.image}
                    title={item.title}
                    description={item.description}
                  >
                    <span>{item.price} </span> / per person
                  </PackageCard>
                ))}
              </div>
              <CustomButton href={"/tours"}>VIEW ALL PACKAGES</CustomButton>
            </div>
          </div>
        </section>

        <section class="destination-section home">
          <div class="container-fluid px-lg-5">
            <TopNotchDeals />
          </div>
        </section>

        <section class="package-section recommended">
          <div class="container-fluid ">
            <SectionHeading
              title="EXPLORE GREAT PLACES"
              headingTextAlignment="text-center"
              containerTextsStyle="col-lg-8 offset-lg-2 mt-5"
            >
              <h4>Book now and save up to 15% with our Last Minute Deals</h4>
            </SectionHeading>
            <div class="package-inner">
              <div class="row px-lg-5">
                {packages.map((item) => (
                  <PackageCard
                    buttonColor={"red"}
                    people={item.people}
                    location={item.location}
                    rating={item.rating}
                    reviews={item.reviews}
                    duration={item.duration}
                    image={item.image}
                    title={item.title}
                    description={item.description}
                  >
                    <del> {item.oldPrice}</del>
                    <span>
                      {" "}
                      <ins>{item.price}</ins>{" "}
                    </span>
                  </PackageCard>
                ))}
              </div>
              <CustomButton href={"/tours"}>VIEW ALL PACKAGES</CustomButton>
            </div>
          </div>
        </section>
        <CallbackSection />

        <section class="activity-section">
          <div class="container-fluid">
            <AdventureAndActivity />
          </div>
        </section>

        <section class="special-section">
          <div class="container">
            <SpecialTravelOffer />
          </div>
        </section>

        <section class="best-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-5">
                <SectionHeading title="OUR TOUR GALLERY">
                  <h2>BEST TRAVELER'S SHARED PHOTOS</h2>
                  <p>
                  Enjoy personalized service with our dedicated team, ensuring your travel experience is seamless and memorable.
                  Our professional guides and 24/7 support are committed to providing exceptional, customized service for every traveler.
                  </p>
                </SectionHeading>

                <figure class="gallery-img">
                  <img src={img12} alt="" />
                </figure>
              </div>
              <div class="col-lg-7">
                <div class="row">
                  <div class="col-sm-6">
                    <figure class="gallery-img">
                      <img src={img13} alt="" />
                    </figure>
                  </div>
                  <div class="col-sm-6">
                    <figure class="gallery-img">
                      <img src={img14} alt="" />
                    </figure>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <figure class="gallery-img">
                      <img src={img15} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ClientSection />

        <SubscribeSection />
        <TestimonialSection />
        <section class="contact-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div
                  class="contact-img"
                  style={{ backgroundImage: `url(${img24})` }}
                ></div>
              </div>
              <div class="col-lg-8">
                <div class="contact-details-wrap">
                  <div class="row">
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon12} alt="" />
                        </div>
                        <ul>
                          <li>
                            <a href="#">{INFO_MAILE}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon13} alt="" />
                        </div>
                        <ul>
                          <li>
                            <a href="#">{PHONE1}</a>
                          </li>

                          <li>
                            <a href="#">{PHONE2}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-4 height-max">
                      <div class="contact-details">
                        <div class="contact-icon">
                          <img src={icon14} alt="" />
                        </div>
                        <ul>
                          <li>{ADDRESS}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="contact-btn-wrap">
                  <h3>LET'S JOIN US FOR MORE UPDATE !!</h3>
                  <Link to="/contact" class="button-primary">
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <WhatsAppButton />
    </>
  );
};

export default Home;
