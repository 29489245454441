import React from 'react'
import SectionHeading from './sectionheading'
import ActivityRow from './activity/activityRow'

const AdventureAndActivity = () => {
    return (
        <>
            <SectionHeading title="TRAVEL BY ACTIVITY" headingTextAlignment="text-center" containerTextsStyle="col-lg-8 offset-lg-2">
                <h2>ADVENTURE &amp; ACTIVITY</h2>
                <p>Step into a world of excitement and discovery as you embark on thrilling journeys and engage in immersive activities. From awe-inspiring natural wonders to captivating cultural encounters, our upcoming travel offers will satisfy your thirst for adventure and create unforgettable moments.</p>
            </SectionHeading>

            <ActivityRow/>
        </>
    )
}

export default AdventureAndActivity