import React from 'react';

const ClientItem = ({ logo }) => (
  <div className="client-item">
    <figure>
      <img src={logo} alt="Client Logo" />
    </figure>
  </div>
);

export default ClientItem;
