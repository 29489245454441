import React from 'react';

const ActivityItem = ({ icon, title, destinations }) => (
  <div className="activity-item">
    <div className="activity-icon">
      <a href="#">
        <img src={icon} alt={title} />
      </a>
    </div>
    <div className="activity-content">
      <h4>
        <a href="#">{title}</a>
      </h4>
      <p>{destinations}</p>
    </div>
  </div>
);

export default ActivityItem;
