import React from "react";
import IneerBanner from "../components/IneerBanner";

export default function PrivacyPolicy() {
  
  return (
    <>
      <IneerBanner bannerName={"Privacy Policy"} />
      <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
        <h2>Effective Date: 1/1/2024</h2>
        <h3>1. Introduction</h3>
        <p>
          Welcome to Album Travel! We value your privacy and are committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, and safeguard your data when you visit our website
          and use our services.
        </p>

        <h3>2. Information We Collect</h3>
        <p>
          <strong>Personal Information:</strong> When you book a trip, subscribe
          to our newsletter, or contact us, we may collect personal details such
          as your name, email address, phone number, and payment information.
        </p>
        <p>
          <strong>Usage Data:</strong> We collect information on how you
          interact with our website, including IP addresses, browser type, pages
          visited, and the date and time of your visit.
        </p>
        <p>
          <strong>Cookies:</strong> We use cookies to enhance your browsing
          experience. Cookies are small data files stored on your device that
          help us remember your preferences and improve our services.
        </p>

        <h3>3. How We Use Your Information</h3>
        <p>We use the collected information for the following purposes:</p>
        <ul>
          <li>To process bookings and transactions.</li>
          <li>
            To send promotional materials and newsletters (you can opt-out at
            any time).
          </li>
          <li>To improve our website and services.</li>
          <li>To respond to your inquiries and provide customer support.</li>
          <li>To comply with legal obligations.</li>
        </ul>

        <h3>4. Sharing Your Information</h3>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your data with:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> Trusted partners who assist us
            in operating our website and conducting our business.
          </li>
          <li>
            <strong>Legal Authorities:</strong> If required by law or to protect
            our rights and property.
          </li>
        </ul>

        <h3>5. Data Security</h3>
        <p>
          We implement appropriate security measures to protect your personal
          information from unauthorized access, alteration, disclosure, or
          destruction.
        </p>

        <h3>6. Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you.</li>
          <li>Request corrections to any inaccurate data.</li>
          <li>Request the deletion of your personal data.</li>
          <li>Opt-out of receiving marketing communications.</li>
        </ul>
        <p>
          To exercise these rights, please contact us at info@albumtravel.com.
        </p>

        <h3>7. Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated effective date. We encourage
          you to review this policy periodically.
        </p>

        <h3>8. Contact Us</h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us:
        </p>
        <p>
          <strong>Phone:</strong> 1033973047
          <br />
          <strong>Email:</strong> info@albumtravel.com
          <br />
          {/* <strong>Website:</strong>{" "}
          <a
            href="http://www.albumtravel.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.albumtravel.com
          </a> */}
        </p>

        <p>
          Thank you for trusting Album Travel with your travel plans and
          personal information. We are committed to ensuring your privacy and
          security.
        </p>
      </div>
    </>
  );
}
