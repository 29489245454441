import React from 'react'

const PostThumb = ({imgSrc, title, date, comments}) => {
    return (
        <li>
            <figure class="post-thumb">
                <a href="#"><img src={imgSrc} alt="" /></a>
            </figure>
            <div class="post-content">
                <h5>
                    <a href="#">{title}</a>
                </h5>
                <div class="entry-meta">
                    <span class="posted-on">
                        <a href="#">{date}</a>
                    </span>
                    <span class="comments-link">
                        <a href="#">{comments}</a>
                    </span>
                </div>
            </div>
        </li>
    )
}

export default PostThumb