import React from 'react'

const ActivityCard = ({ imgSrc, title, destinationNum }) => {
    return (
        <>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <div class="activity-item">
                    <div class="activity-icon">
                        <a href="#">
                            <img src={imgSrc} alt=""/>
                        </a>
                    </div>
                    <div class="activity-content">
                        <h4>
                            <a href="#">{title}</a>
                        </h4>
                        <p>{`${destinationNum} Destination`}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivityCard