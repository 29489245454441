import React from 'react';

const AboutService = ({ icon, title, description }) => (
  <div className="about-service">
    <div className="about-service-icon">
      <img src={icon} alt={title} />
    </div>
    <div className="about-service-content">
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  </div>
);

export default AboutService;
