import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SubscribeSection from "../components/subscribeSection";
import DistiItem from "../components/distiItem";

import img1 from "../images/jordan.jpg";
import img2 from "../images/palestine.jfif";
import img3 from "../images/khalifa-dubai.jfif";
import img4 from "../images/img4.jpg";
import IneerBanner from "../components/IneerBanner";

const Destination = () => {
  return (
    <>
      <main id="content" class="site-main">
        <IneerBanner bannerName={"Destination"} />

        <section class="destination-section destination-page">
          <div class="container">
            <div class="destination-inner destination-three-column">
              <div class="destination-inner destination-three-column">
                <div class="row">
                  <div class="col-lg-7">
                    <div class="row">
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img1}
                          country="Jordan"
                          content="jordan Capital"
                        />
                      </div>
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img2}
                          country="palestine"
                          content="Al-Aqsa Mosque"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row">
                      <div class="col-md-6 col-xl-12">
                        <DistiItem
                          imgSrc={img3}
                          country="Dubai"
                          content="khalifa tower"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-5">
                    <div class="row">
                      <div class="col-md-6 col-xl-12">
                        <DistiItem
                          imgSrc={img3}
                          country="NEW ZEALAND"
                          content="Oxolotan City"
                        />
                      </div>
                      <div class="col-md-6 col-xl-12">
                        <DistiItem
                          imgSrc={img4}
                          country="SINGAPORE"
                          content="Marina Bay Sand City"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div class="row">
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img1}
                          country="THAILAND"
                          content="Disney Land"
                        />
                      </div>
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img2}
                          country="NORWAY"
                          content="Besseggen Ridge"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscribeSection />
      </main>
    </>
  );
};

export default Destination;
