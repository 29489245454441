import React from "react";
import { children } from "react";

const ArchiveCard = ({ imgSrc, title, date, comments, children }) => {
  return (
    <>
      <article class="post">
        <figure class="feature-image">
          <a href="#">
            <img src={imgSrc} alt="" />
          </a>
        </figure>
        <div class="entry-content">
          <h3>
            <a href="blog-single.html">{title}</a>
          </h3>
          <div class="entry-meta">
            <span class="posted-on">
              <a href="blog-single.html">{date}</a>
            </span>
            <span class="comments-link">
              <a href="blog-single.html">{comments}</a>
            </span>
          </div>
          <p>{children}...</p>
          {/* <a href="blog-single.html" class="button-text">
            ...
          </a> */}
        </div>
      </article>
    </>
  );
};

export default ArchiveCard;
