import React from 'react'
import { Link } from 'react-router-dom'

const DestinationCard = ({imgSrc}) => {
    return (
        <>
            <div class="col-md-4">
                <div class="desti-item overlay-desti-item">
                    <figure class="desti-image">
                        <img src={imgSrc} alt="" />
                    </figure>
                    <div class="meta-cat bg-meta-cat">
                        <div class="desti-content">
                            <h3>
                                <Link to="/coming-soon">Global Tour Rewards</Link>
                            </h3>
                            <p>These deals won't last long</p>
                        </div>
                        <Link to="/coming-soon">See rewards</Link>
                    </div>

                </div>
            </div>
        </>
    )
}

export default DestinationCard