import React from "react";
import IneerBanner from "../components/IneerBanner";
import Footer from "../components/footer";
import SingleTour from "../components/PackageDetailComponents/SingleTour";
import SubscribeSection from "../components/subscribeSection";
import Header from "../components/header";

export default function PackageDetail() {
  return (
    <>
      <IneerBanner bannerName={"Package Detail"} />
      <SingleTour />
      <SubscribeSection />
    </>
  );
}
