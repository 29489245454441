import React from "react";
import Header from "../components/header";
import IneerBanner from "../components/IneerBanner";
import Footer from "../components/footer";
import ContactSection from "../components/ContactComponents/ContactSection";

export default function Contact() {
  return (
    <>
      <IneerBanner bannerName={"Contact us"} />
      <ContactSection />
    </>
  );
}
