import React, { useState } from "react";
import { Link } from "react-router-dom";
const PackageWrap = ({
  image,
  price,
  duration,
  people,
  location,
  title,
  reviews,
  rating,
  description,
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const getShortDescription = (text) => {
    return text.length > 200 ? text.substring(0, 200) + "..." : text;
  };

  return (
    <div className="package-wrap">
      <figure className="feature-image">
        <Link to="https://wa.me/+201033973047">
          <img src={image} alt="" />
        </Link>
      </figure>
      <div className="package-price">
        <h6>
          <span>{price} </span> / per person
        </h6>
      </div>
      <div className="package-content-wrap">
        <div className="package-meta text-center">
          <ul>
            <li>
              <i className="far fa-clock"></i>
              {duration}
            </li>
            <li>
              <i className="fas fa-user-friends"></i>
              People: {people}
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              {location}
            </li>
          </ul>
        </div>
        <div className="package-content">
          <h3>
            <Link to="https://wa.me/+201033973047">{title}</Link>
          </h3>
          <div className="review-area">
            <span className="review-text">({reviews})</span>
            <div className="rating-start" title="Rated 5 out of 5">
              <span style={{ width: rating }}></span>
            </div>
          </div>
          <p>
            {showFullDescription
              ? description
              : getShortDescription(description)}
            {description.length > 100 && (
              <button onClick={toggleDescription} className="toggle-button">
                {showFullDescription ? "See Less" : "See More"}
              </button>
            )}
          </p>
          <div className="btn-wrap">
            <Link to="https://wa.me/+201033973047" className="button-text width-6">
              Book Now<i className="fas fa-arrow-right"></i>
            </Link>
            <Link to="https://wa.me/+201033973047" className="button-text width-6">
              Wish List<i className="far fa-heart"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageWrap;
