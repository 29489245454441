import React, {useEffect} from "react";
import innerBanner from "../images/inner-banner.jpg";

export default function IneerBanner({ bannerName }) {
  useEffect(()=>{
    document.getElementById("top").scrollIntoView({'behavior':'auto'})
  })

  return (
    <section className="inner-banner-wrap" id="top">
      <div
        className="inner-baner-container"
        style={{ backgroundImage: `url(${innerBanner})` }}
      >
        <div className="container">
          <div className="inner-banner-content">
            <h1 className="inner-title">{bannerName}</h1>
          </div>
        </div>
      </div>
      <div className="inner-shape"></div>
    </section>
  );
}
