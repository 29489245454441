import React from 'react'
import ActivityCard from './activityCard'

import icon6 from "../../images/icon6.png";
import icon10 from "../../images/icon10.png";
import icon9 from "../../images/icon9.png";
import icon8 from "../../images/icon8.png";
import icon7 from "../../images/icon7.png";
import icon11 from "../../images/icon11.png";



const ActivityRow = () => {
    return (
        <>
            <div class="activity-inner row">
                <ActivityCard imgSrc={icon6} title="Adventure" destinationNum={15}/>
                <ActivityCard imgSrc={icon10} title="Trekking" destinationNum={12}/>
                <ActivityCard imgSrc={icon9} title="Camp Fire" destinationNum={7}/>
                <ActivityCard imgSrc={icon8} title="Off Road" destinationNum={15}/>
                <ActivityCard imgSrc={icon7} title="Camping" destinationNum={13}/>
                <ActivityCard imgSrc={icon11} title="Exploring" destinationNum={25}/>
            </div>
        </>
    )
}

export default ActivityRow