import React from 'react'
import DestinationCard from './destinationCard'

import img1 from "../../images/img1.jpg";
import img2 from "../../images/img2.jpg";
import img3 from "../../images/img3.jpg";
import CustomButton from '../shared/customButton';

const DestinationRow = () => {
    return (
        <>
            <div class="destination-inner destination-three-column">
                <div class="row">
                    <DestinationCard imgSrc={img1}/>
                    <DestinationCard imgSrc={img2}/>
                    <DestinationCard imgSrc={img3}/>
                </div>
                <CustomButton>MORE DESTINATION</CustomButton>
            </div>
        </>
    )
}

export default DestinationRow