import React, { useEffect } from 'react'
import errorImg from "../images/404-img.jpg"

const ComingSoon = () => {
  useEffect(()=>{
    document.getElementById("soon").scrollIntoView({'behavior':'auto'})
  })

  return (
    <>
      <main id="content" class="site-main">
        <div class="no-content-section 404-page" id="soon" style={{ backgroundImage: `url(${errorImg})` }}>
          <div class="container">
            <div class="no-content-wrap">
              <span>Soon</span>
              <h1>Our Special Travel Offers Coming Soon</h1>
              <h4>Exciting news coming your way! Stay tuned for our upcoming special travel offers, where you'll discover incredible deals on dream destinations, luxurious accommodations, and unforgettable experiences.<br/> We suggest you to try searching!</h4>
              <div class="search-form-wrap">
                <form class="search-form">
                  <input type="text" name="search" placeholder="Search..." />
                  <button class="search-btn"><i class="fas fa-search"></i></button>
                </form>
              </div>
            </div>
          </div>
          <div class="overlay"></div>
        </div>
      </main>

    </>
  )
}

export default ComingSoon