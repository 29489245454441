import React from "react";
import Slider from "react-slick";
import logo1 from "../images/logo1.png";
import logo2 from "../images/logo2.png";
import logo3 from "../images/logo3.png";
import logo4 from "../images/logo4.png";

const ClientItem = ({ imgSrc }) => (
  <div className="client-item">
    <figure>
      <img src={imgSrc} alt="" />
    </figure>
  </div>
);

const ClientSection = () => {
  const clientLogos = [logo1, logo2, logo3, logo4, logo1, logo2];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: false,
  };
  return (
    <div className="client-section">
      <div className="container-fluid">
        <div className="client-wrap client-slider secondary-bg">
          <Slider {...settings}>
            {clientLogos.map((logo, index) => (
              <ClientItem key={index} imgSrc={logo} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientSection;
