import React from 'react'
import { children } from 'react';

const Banner = ({children, innerBanner}) => {
    return (
        <section class="inner-banner-wrap">
            <div class="inner-baner-container" style={{ backgroundImage: `url(${innerBanner})` }}>
                <div class="container">
                    <div class="inner-banner-content">
                        <h1 class="inner-title">{children}</h1>
                    </div>
                </div>
            </div>
            <div class="inner-shape"></div>
        </section>
    )
}

export default Banner