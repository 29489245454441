import React, { useState } from "react";
import { Link } from "react-router-dom";

const SpecialCard = ({
  imgSrc,
  discount,
  country,
  description,
  oldPrice,
  price,
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  const getShortDescription = (text) => {
    return text.length > 200 ? text.substring(0, 50) + "..." : text;
  };

  return (
    <>
      <div class="col-md-6 col-lg-4">
        <div class="special-item">
          <figure class="special-img">
            <img src={imgSrc} alt="" />
          </figure>
          <div class="badge-dis">
            <span>
              <strong>{`${discount}%`}</strong>
              off
            </span>
          </div>
          <div class="special-content">
            <div class="meta-cat">
              <Link to="/coming-soon">{country}</Link>
            </div>
            <h3>
              <Link to="/coming-soon">
                {showFullDescription
                  ? description
                  : getShortDescription(description)}
                {description.length > 100 && (
                  <button onClick={toggleDescription} className="toggle-button">
                    {showFullDescription ? "See Less" : "See More"}
                  </button>
                )}
              </Link>
            </h3>
            <div class="package-price">
              Price:
              <del>{`$${oldPrice}`}</del>
              <ins>{`$${price}`}</ins>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialCard;
